import styled from '@emotion/styled';

export const ContentPaddingStyle = styled.div`
  padding-top: 10px;
`;

export const ButtonSectionStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const SnowflakeInputSectionStyle = styled.div`
  display: inline;
  margin-right: 20px;
`;

export const SnowflakeInputRowSectionStyle = styled.div`
  margin-top: 20px;
`;

export const TopMessageInProgress = styled.div`
  margin-top: -15px;
  margin-bottom: 15px;
  padding: 5px;
`;

export const TopMessageCompleted = styled.div`
  margin-top: -15px;
  margin-bottom: 15px;
  padding: 5px;
`;