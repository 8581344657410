import { Box, Grid } from "@mui/material"
import { Card, Chart, ChartIntervals, Chip, Spinner, Tooltip, Typography, designTokens } from "@platform-ui/design-system"
import React, { Dispatch, useCallback, useEffect, useMemo, useState } from "react"
import { ExistingDestination, useGetTransfers } from "@prequel/react";
import { DataItem } from "@platform-ui/design-system/dist/types/Chart";
import { Action, useStoreContext } from "../../../../Store";
import { IADState } from "../../../../IntegrationApps/IntegrationAppDetails/state";
import fetchAuthTokenWithExistingDestination from "../fetchTokens/fetchAuthTokenWithExistingDestination";
import { AUTH_TOKEN_URL, PREQUEL_API_HOST, T9_PERMISSION_MAPPINGS } from "../Constants";
import Connect from "../../../../Connect/Connect";
import MetricsInfoCard from "./MetricsInfoCard";
import { useIdentity } from "../../../../IdentityProvider";
import { IdentityPermissions } from "../../../../IdentityProvider/constants/permissions";
import { ZDQ_DESTINATION_DATA, ZDQ_RECIPIENT } from "../action_types";
import _ from 'lodash';

declare type TransferData = {
    day: string,
    total_rows_transferred: number,
    total_transfers_completed: number,
    total_volume_transferred_in_mb: number
}

const LAST_SYNC_STATUS_MAPPINGS = {
    "CANCELLED": { label: "Cancelled", state: "warning", icon: "cancel" },
    "ERROR": { label: "Error", state: "error", icon: "info" },
    "EXPIRED": { label: "Expired", state: "indeterminate", icon: "warning" },
    "PARTIAL_FAILURE": { label: "Error", state: "error", icon: "info" },
    "PENDING": { label: "Pending", state: "info", icon: "radio_button_on" },
    "RUNNING": { label: "Running", state: "info", icon: "radio_button_on" },
    "SUCCESS": { label: "Success", state: "success", icon: "done" },
    "ORPHANED": { label: "Orphaned", state: "warning", icon: "warning" },
    undefined: { label: "Unknown", state: "warning", icon: "warning" },
}

function ZdqConnectorStatus({ vendor }) {

    // States
    const [chartData, setChartData] = useState<DataItem[]>([]);
    const [totalRowsSynced, setTotalRowsSynced] = useState<string>('0');
    const [showSpinner, setShowSpinner] = useState(false);
    const [lastTransfer, setLastTransfer] = useState(null);
    const [shareLatency, setShareLatency] = useState<string>(null);
    const isLocal = window.location.host.includes('localhost');
    const proxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;

    // Context
    const { state } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
    const destinationData = state.settingsHash[ZDQ_DESTINATION_DATA];
    const recipient = state.settingsHash[ZDQ_RECIPIENT];
    const fetchAuthTokenUrl = `${state.settingsUrl.replace('.json', AUTH_TOKEN_URL)}`;

    // T9 Permissions
    const { hasPermission } = useIdentity();
    const hasPermissionsEnabled = hasPermission(`permission.${T9_PERMISSION_MAPPINGS[vendor]}` as IdentityPermissions);

    const getNextTransferTime = (() => {
        if (shareLatency && (lastTransfer?.started_at || lastTransfer?.submitted_at)) {
            const lastTransferAttempt = new Date(lastTransfer.started_at || lastTransfer.submitted_at);
            const lastTransferDifferenceAgainstCurrentTimeInSeconds = (new Date().getTime() - lastTransferAttempt?.getTime()) / 1000;
            const shareLatencyInSeconds = parseInt(shareLatency);
            const nextTransferTime: number = Math.abs(shareLatencyInSeconds - lastTransferDifferenceAgainstCurrentTimeInSeconds) / (60 * 60)

            if (_.isNaN(shareLatencyInSeconds) || _.isNaN(nextTransferTime)) {
                Connect.log(`Next transfer time error:
                    shareLatencyInMinutes = ${shareLatencyInSeconds}, 
                    nextTransferTime ${nextTransferTime} 
                    lastTransferAttempt = ${lastTransferAttempt}`);
                return 'In about unknown hours'
            }

            /* Ideally, there should be never be a case when the shareLatencyInSeconds > nextTransferTime because the transfer take place every 'shareLatencyInSeconds' seconds.
               But, megastore works in a way such that it might take more that 'shareLatencyInSeconds' time for the next transfer.
               Therefore, returning the 'shareLatencyInSeconds' value directly until a transfer takes place.
            */
            if (shareLatencyInSeconds < lastTransferDifferenceAgainstCurrentTimeInSeconds) {
                return `In about ${Math.ceil(shareLatencyInSeconds < 3600 ? shareLatencyInSeconds / 60 : shareLatencyInSeconds / 3600)} ${shareLatencyInSeconds < 3600 ? ' minutes' : ' hours'}`;
            }
            return `In about ${Math.ceil(nextTransferTime < 1 ? nextTransferTime * 60 : nextTransferTime)} ${nextTransferTime < 1 ? ' minutes' : ' hours'}`;
        }
        return 'In about unknown hours'
    })
    const gridItems = useMemo(
        () => [
            { id: '1', title: 'Last successful Transfer Completed', tooltip: 'This timestamp marks the completion of the most recent successful data transfer to your destination. It reflects the latest data available in your system.', data: destinationData?.last_successful_transfer_ended_at || '' },
            { id: '2', title: 'Next Scheduled Transfer Starts', tooltip: 'This timestamp indicates when the next synchronization process will commence', data: getNextTransferTime() },
            { id: '3', title: 'Total Rows Synced (last 30 days)', tooltip: 'This indicates the total number of rows synchronized during the last 30 days. It provides insight into the recent data transfer activity for the connector.', data: '' }
        ],
        [shareLatency, lastTransfer]
    );

    // Transfer Hook
    const fetchAuthTokenWithDestinationWrapper = useCallback(async (existingDestination: ExistingDestination) => {
        return await fetchAuthTokenWithExistingDestination(fetchAuthTokenUrl, existingDestination);
    }, []);
    const getTransfers = useGetTransfers(fetchAuthTokenWithDestinationWrapper, window.location.host, PREQUEL_API_HOST);

    // All transfers to display the chart
    async function fetchAllTransfers() {
        try {
            const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
            const options = {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-Token': csrf
                })
            };
            const response = await window.fetch(`${state.settingsUrl.replace('.json', `/zdq/transfers?destination_id=${destinationData?.id}`)}`, options);
            const data = await response.json();
            if (!response.ok) {
                throw Error(`Error fetching All transfers for the destination: ${destinationData?.id}, message: ${data.message}, statusText: ${response.statusText}`);
            }
            return data;

        } catch (error) {
            Connect.log(error);
        }
    }

    // Get the latency for the tenant from tenant-registry
    const fetchTenantLatency = async () => {
        try {
            const response = await Connect.proxyCall(
                `${proxyPath}/tenant-registry/tenantsServiceInfo/${recipient?.id_in_provider_system}?name=ZWAREHOUSECONNECTOR&type=WAREHOUSE`,
                'GET',
                undefined,
                { 'Accept': 'application/json', 'Content-Type': 'application/json' }
            );
            if (!response.ok) {
                const body = await response.json()
                throw new Error(`Error fetchTenantLatency: ${body?.message}, statusText: ${response.statusText}, tenant: ${recipient?.id_in_provider_system}`);
            }
            const res = await response.json();
            if (res.length !== 1) {
                throw new Error("None or More than one element found in the tenants service info");
            }
            return res[0]?.info?.shareLatencyInSeconds
        } catch (error) {
            Connect.log(`Error fetching latency for the ${recipient?.id_in_provider_system} tenant: ${error}`);
        }
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                setShowSpinner(true);
                const lastTransferResponse = await getTransfers(destinationData, { count: 1 })
                if (!lastTransferResponse) {
                    throw Error("Error while fetching last transfer");
                }

                if (lastTransferResponse?.length === 1) {
                    setLastTransfer(lastTransferResponse[0]);
                    // If the lastTransfer is success, only then need to make this lactency call
                    const latency = await fetchTenantLatency();
                    setShareLatency(latency)
                }
                Connect.log('Zdq last transfer empty response: ', lastTransferResponse.length);
            } catch (error) {
                Connect.log('Zdq last transfer or latency call failed: ', error);
            } finally {
                setShowSpinner(false);
            }

            try {
                setShowSpinner(true);
                const allTransfers = await fetchAllTransfers()
                if (!allTransfers || allTransfers.error) {
                    Connect.log(`Error while fetching transfers: ${allTransfers.error}`);
                    return;
                }
                // TODO: This is done because the dates shown in the chart are not the dates that are fetched (+,- 1 day on both ends). 
                setChartData(allTransfers?.map((data: TransferData) => { return { ...data, x: data.day + "T07:00:00.000Z", y: data.total_rows_transferred } }));
                const temp = allTransfers.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.total_rows_transferred;
                }, 0)
                setTotalRowsSynced(temp.toLocaleString());
            } catch (error) {
                Connect.log('Zdq all transfer: ', error);
            } finally {
                setShowSpinner(false);
            }

        }

        if (hasPermissionsEnabled && recipient && !_.isEmpty(destinationData)) {
            fetch();
        }
    }, [recipient, destinationData])


    if (!hasPermissionsEnabled) {
        return <Card autoDistance id="disabled-tenant-card" body={
            <Typography color={designTokens.colors.coolGray300}
                sx={{ marginTop: '5%', marginBottom: '5%', textAlign: 'center' }}
                variant="body1"
                body="You don't have permissions enabled to view this tab. Please contact Zuora support if you are seeing this message." />}
        />
    }

    if (showSpinner || destinationData === undefined) {
        return <Spinner e2e="connector-status-spinner" size="large"></Spinner>
    }

    if (!recipient) {
        return <Card autoDistance id="connector-setup-no-recipient-or-models" body={
            <Typography color={designTokens.colors.coolGray300}
                sx={{ marginTop: '5%', marginBottom: '5%', textAlign: 'center' }}
                variant="body1"
                body="Error while fetching the recipient details for this tenant. Please contact Zuora support if you are seeing this message." />
        }
        />
    }

    if (!_.isEmpty(destinationData)) {
        return <Box>
            <Card autoDistance id='connector-status-metrics'
                header='Connector Metrics' titleBar
                noPaddingBottom
                body={
                    <Grid container direction='column'>
                        <Grid item>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography sx={{ marginLeft: '8px', marginRight: '8px' }} variant="body1"
                                    color={designTokens.colors.coolGray500} body="Sync Transfer Status:" />
                                <Tooltip
                                    e2e="last-sync-status"
                                    title="Information Not Available"
                                    dsOnClose={() => { }}
                                    dsOnOpen={() => { }}
                                >
                                    <Chip label={LAST_SYNC_STATUS_MAPPINGS[lastTransfer?.status]?.label}
                                        state={LAST_SYNC_STATUS_MAPPINGS[lastTransfer?.status]?.state} 
                                        icon={LAST_SYNC_STATUS_MAPPINGS[lastTransfer?.status]?.icon} />
                                </Tooltip>
                            </Box>
                        </Grid>
                        <Grid container direction='row'>
                            <Grid item xs={4}>
                                <MetricsInfoCard data={gridItems[0].data ? new Intl.DateTimeFormat('en-US', {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric'
                                }).format(new Date(gridItems[0].data)) : 'No Transfer Completed Yet'} title={gridItems[0].title} tooltip={gridItems[0].tooltip} />
                            </Grid>
                            <Grid item xs={4}>
                                <MetricsInfoCard data={gridItems[1].data} title={gridItems[1].title} tooltip={gridItems[1].tooltip} ></MetricsInfoCard>
                            </Grid>
                            <Grid item xs={4}>
                                <MetricsInfoCard data={totalRowsSynced} title={gridItems[2].title} tooltip={gridItems[2].tooltip} ></MetricsInfoCard>
                            </Grid>
                        </Grid>
                    </Grid>
                }>
            </Card >
            <Card autoDistance
                id='connector-status-rows-synced'
                header='Rows Synced'
                titleBar
                noPaddingBottom
                body={
                    <Box sx={{ '> div': { fontSize: '1.5rem' } }}>
                        <Typography color={designTokens.colors.coolGray400}>Last 30 days</Typography>
                        <Chart
                            colorPalette="single"
                            type="bar"
                            data={chartData}
                            xInterval={ChartIntervals.DAY}
                            yAxisIntegerOnly
                            // locale='en-US'
                            showAllXAxisLabel
                            tooltip={(props) =>
                                <Grid container sx={{ margin: '10' }}>
                                    <Grid item>
                                        <Typography variant="title" body={chartData[props.currIndex]?.x ? new Intl.DateTimeFormat('en-US', {
                                            month: 'long',
                                            day: 'numeric',
                                            year: 'numeric'
                                        }).format(new Date(chartData[props.currIndex].x)) : 'Unknown'} />


                                    </Grid>
                                    <Grid container direction="row" justifyContent="space-between" sx={{ marginTop: '10%' }}>
                                        <Grid item><Typography variant="subtitle1">Rows Synced</Typography></Grid>
                                        <Grid item><Typography variant="subtitle1" body={chartData[props.currIndex].y || '0'} /></Grid>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </Box>
                }
            >
            </Card>
        </Box >
    }
    return <Card autoDistance id="connector-status-no-destination" body={
        <Typography color={designTokens.colors.coolGray300} sx={{ marginTop: '5%', marginBottom: '5%', textAlign: 'center' }} variant="body1" body="No destination has been configured for this tenant. Please add a destination to see its status. If you have added a destination and still see this message, please contact zuora support."></Typography>} />
}

export default ZdqConnectorStatus